<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Edit Data Karyawan</strong>
              </h5>
            </template>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-tabs content-class="mt-3" align="center">
                  <b-tab title="Profil Karyawan" active>
                    <TabProfil />
                  </b-tab>
                  <b-tab title="Absensi">
                    <TabAbsensi />
                  </b-tab>
                  <b-tab title="Penilaian">
                    <TabPenilaian />
                  </b-tab>
                  <b-tab title="Pelanggaran"> <TabPelanggaran /> </b-tab>
                  <b-tab title="Peringatan">
                    <TabPeringatan />
                  </b-tab>
                  <b-tab title="Perjanjian">
                    <TabPerjanjian />
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import TabProfil from "../../../component/hcms/karyawan/tabProfil.vue";
// import TabAbsensi from "../../../component/hcms/karyawan/tabAbsensi.vue";
// import TabPenilaian from "../../../component/hcms/karyawan/tabPenilaian.vue";
// import TabPelanggaran from "../../../component/hcms/karyawan/tabPelanggaran.vue";
// import TabPeringatan from "../../../component/hcms/karyawan/tabPeringatan.vue";
// import TabPerjanjian from "../../../component/hcms/karyawan/tabPerjanjian.vue";
export default {
  name: "editKaryawan",
  props: ["data", "edit"],
  components: {
    // TabProfil,
    // TabAbsensi,
    // TabPenilaian,
    // TabPelanggaran,
    // TabPeringatan,
    // TabPerjanjian,
  },
  data() {
    return {};
  },
  watch: {
    edit: function (newVal, oldVal) {
      if (oldVal != newVal) {
        // console.log(this.data, "ini datanya");
      }
    },
  },
};
</script>
